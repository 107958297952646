import { Dispatch, SetStateAction } from "react";
import { TopShunter } from "../../components/topShunter/TopShunter";
import { ShunterQuestion } from "../../components/shunterQuestion/ShunterQuestion";
import { Footer } from "../../components/footer/Footer";

type ShunterProps = {
  enseigneName: string;
  enseignePlaceId?: string;
  enseigneSentence: string;
  enseigneCover: string;
  id: string;
  setIsClickedWithoutPlaceId: Dispatch<SetStateAction<boolean>>;
  shunterPoint?: number;
};

export function Shunter({
  enseigneName,
  enseignePlaceId,
  enseigneCover,
  enseigneSentence,
  id,
  setIsClickedWithoutPlaceId,
  shunterPoint,
}: ShunterProps) {
  return (
    <>
      <TopShunter enseigneName={enseigneName} enseigneCover={enseigneCover} />
      <ShunterQuestion
        enseignePlaceId={enseignePlaceId}
        enseigneSentence={enseigneSentence}
        id={id}
        setIsClickedWithoutPlaceId={setIsClickedWithoutPlaceId}
        shunterPoint={shunterPoint}
      />
      <Footer />
    </>
  );
}
