import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DocumentData, DocumentReference, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Enseigne } from "../types";

function isEnseigne(data: unknown): data is Enseigne {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  const { name, cover, tags, sentence, place_id, shunterPoint } = data as Enseigne;
  if (
    typeof name !== "string" ||
    typeof cover !== "string" ||
    !Array.isArray(tags) ||
    tags.some((tag) => typeof tag !== "string") ||
    (sentence !== undefined && typeof sentence !== "string") ||
    (place_id !== undefined && typeof place_id !== "string") ||
    (shunterPoint !== undefined && typeof shunterPoint !== "number")
  ) {
    return false;
  }

  return true;
}

export const useFetch = () => {
  const navigate = useNavigate();
  const [enseigne, setEnseigne] = useState<Enseigne>({ name: "", cover: "", tags: [], sentence: "", place_id: "", shunterPoint: 5 });
  const [idEnseigne, setIdEnseigne] = useState("");

  const getAPIData = async () => {
    const { id } = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    if (id === "" || id === undefined) {
      navigate("/404-not-found");
    }
    setIdEnseigne(id);

    let enseigneRef: DocumentReference<DocumentData>;
    try {
      enseigneRef = doc(db, "enseignes", id);
    } catch (error) {
      console.log(error);
      return;
    }

    const fetchedData = await getDoc(enseigneRef);

    if (!fetchedData.exists()) {
      navigate("/404-not-found");
      return;
    }

    const data = fetchedData.data();

    if (!isEnseigne(data)) {
      navigate("/404-not-found");
      return;
    }
    const { name, cover, tags, sentence, place_id, shunterPoint } = data;

    setEnseigne((prev) => ({
      ...prev,
      name,
      cover,
      tags,
      sentence,
      place_id,
      shunterPoint,
    }));
  };

  useEffect(() => {
    getAPIData();
  }, []);

  return { enseigne, idEnseigne };
};
