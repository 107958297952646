import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Shunter } from "./pages/shunter/Shunter";
import { TagView } from "./pages/tagView/TagView";
import { ThanksView } from "./pages/thanksView/ThanksView";
import { NotFound } from "./pages/NotFound/NotFound";
import { useFetch } from "./api/useFetch";

export function App() {
  const { enseigne, idEnseigne } = useFetch();
  const [isClickedWithoutPlaceId, setIsClickedWithoutPlaceId] = useState<boolean>(false);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Shunter
            enseigneName={enseigne.name}
            enseigneSentence={enseigne.sentence}
            enseignePlaceId={enseigne.place_id}
            enseigneCover={enseigne.cover}
            id={idEnseigne}
            shunterPoint={enseigne.shunterPoint}
            setIsClickedWithoutPlaceId={setIsClickedWithoutPlaceId}
          />
        }
      />
      <Route path="/tag" element={<TagView id={idEnseigne} enseigne={enseigne} isClickedWithoutPlaceId={isClickedWithoutPlaceId} />} />
      <Route path="/thanks" element={<ThanksView enseigneName={enseigne.name} enseigneCover={enseigne.cover} />} />
      <Route path="/404-not-found" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
