import topImage from "../../assets/topImage.webp";

type TopShunterProps = {
  enseigneName?: string;
  enseigneCover: string;
};

export function TopShunter({ enseigneName, enseigneCover }: TopShunterProps) {
  return (
    <div className="topShunter">
      <img src={enseigneCover ? enseigneCover : topImage} alt="lieu" />
      {!!enseigneName && <h1 className="topShunter_title">{enseigneName}</h1>}
    </div>
  );
}
