import { Footer } from "../../components/footer/Footer";

export function NotFound() {
  return (
    <>
      <main className="notFound">
        <div>404 Je suis triste je connais pas cette enseigne. Venez la créer sur elyse-app.com. Je veux apprendre à vous connaitre ☺️</div>
      </main>
      <Footer />
    </>
  );
}
