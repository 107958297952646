import { Dispatch, SetStateAction, useState } from "react";

type TagProps = {
  tag: string;
  setSelectedTags: Dispatch<SetStateAction<string[]>>;
  selectedTags: string[];
};

export function Tag({ tag, setSelectedTags, selectedTags }: TagProps) {
  const [isClicked, setIsClicked] = useState<boolean>(false);

  /**
   * If the tag is already selected, remove it from the selectedTags array. Otherwise, add it to the
   * selectedTags array
   */

  const clickHandler = () => {
    setIsClicked((current) => !current);

    if (selectedTags.includes(tag)) {
      setSelectedTags((prev) => prev.filter((selectedTag) => selectedTag !== tag));
    } else {
      setSelectedTags((prev) => [tag, ...prev]);
    }
  };

  return (
    <button className={isClicked ? "tag clicked" : "tag"} onClick={clickHandler}>
      {tag}
    </button>
  );
}
