import { TopShunter } from "../../components/topShunter/TopShunter";
import { Footer } from "../../components/footer/Footer";

type ThanksViewProps = {
  enseigneName: string;
  enseigneCover: string;
};

export function ThanksView({ enseigneName, enseigneCover }: ThanksViewProps) {
  return (
    <>
      <TopShunter enseigneCover={enseigneCover} />
      <p className="thanks">Toute l&apos;équipe de {enseigneName} vous remercie !</p>
      <p className="seeYou">On espère vous revoir bientôt, comptez sur nous pour prendre en compte vos retours.</p>
      <Footer />
    </>
  );
}
