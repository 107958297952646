import { useState, Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { Timestamp, addDoc, collection, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { ReviewType } from "../../types";
import grinning from "../../assets/emojis/grinning-face_1f600.png";
import smilling from "../../assets/emojis/slightly-smiling-face_1f642.png";
import neutral from "../../assets/emojis/neutral-face_1f610.png";
import pouting from "../../assets/emojis/pouting-face_1f621.png";
import anguished from "../../assets/emojis/anguished-face_1f627.png";

type ShunterQuestionProps = {
  enseignePlaceId?: string;
  enseigneSentence: string;
  id: string;
  setIsClickedWithoutPlaceId: Dispatch<SetStateAction<boolean>>;
  shunterPoint?: number;
};

export function ShunterQuestion({ enseignePlaceId, enseigneSentence, id, setIsClickedWithoutPlaceId, shunterPoint }: ShunterQuestionProps) {
  const urlGoogleReview: string = `https://search.google.com/local/writereview?placeid=${enseignePlaceId}`;
  const [emojis, setEmojis] = useState([pouting, anguished, neutral, smilling, grinning]);

  const handleWithPlaceId = async () => {
    const review: ReviewType = {
      creation_date: Timestamp.fromDate(new Date()),
      id_enseigne: doc(db, "enseignes", id),
      positive: true,
      tags: [],
    };
    try {
      addDoc(collection(db, "reviews"), review);
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const handleWithoutPlaceId = () => {
    setIsClickedWithoutPlaceId(true);
  };

  const content = emojis.map((emoji, i) => {
    const isAfterOrAtShunterPoint = shunterPoint && i >= shunterPoint - 1;
    const key = `${i}${Object.keys(emoji)}`;

    if (isAfterOrAtShunterPoint && enseignePlaceId) {
      return (
        <a key={key} href={urlGoogleReview} target={"_top"} onClick={handleWithPlaceId}>
          <img src={emoji} alt="emoji" className="icone" />
        </a>
      );
    } else if (isAfterOrAtShunterPoint && !enseignePlaceId) {
      return (
        <Link key={key} to={"/tag"} onClick={handleWithoutPlaceId}>
          <img src={emoji} alt="emoji" className="icone" />
        </Link>
      );
    }

    return (
      <Link key={key} to={"/tag"}>
        <img src={emoji} alt="emoji" className="icone" />
      </Link>
    );
  });

  return (
    <main className="shunterQuestion">
      <h2>{!!enseigneSentence ? enseigneSentence : "Avez-vous aimé ce lieu ?"}</h2>
      <div className="icones">{content}</div>
    </main>
  );
}
