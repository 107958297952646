import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { Timestamp, addDoc, collection, doc } from "firebase/firestore";
import { TopShunter } from "../../components/topShunter/TopShunter";
import { Tag } from "../../components/tag/Tag";
import { Footer } from "../../components/footer/Footer";
import { Enseigne, ReviewType } from "../../types";

type TagViewProps = {
  id: string;
  enseigne: Enseigne;
  isClickedWithoutPlaceId: boolean;
};

export function TagView({ id, enseigne, isClickedWithoutPlaceId }: TagViewProps) {
  const navigate = useNavigate();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  /**
   * It takes an event, checks if the selectedTags array is empty, if it is, it logs a message to the
   * console, if it isn't, it creates a review object, logs it to the console, and then navigates to the
   * /thanks page
   */
  const submitTags = async () => {
    const review: ReviewType = {
      creation_date: Timestamp.fromDate(new Date()),
      id_enseigne: doc(db, "enseignes", id),
      positive: isClickedWithoutPlaceId ? true : false,
      tags: selectedTags,
    };
    try {
      await addDoc(collection(db, "reviews"), review);
    } catch (error) {
      console.error(error);
      return;
    }

    navigate("/thanks", { replace: true });
  };

  return (
    <>
      <TopShunter enseigneCover={enseigne.cover} enseigneName={enseigne.name} />
      <p className="impress">Laissez nous vos impressions :</p>
      <main className="tagContainer">
        <div className="container">
          {enseigne.tags.map((tag, i) => (
            <Tag key={i + `${Object.keys(tag)}`} tag={tag} setSelectedTags={setSelectedTags} selectedTags={selectedTags} />
          ))}
        </div>
      </main>
      <div className="buttonContainer">
        <button className="envoyer" disabled={!selectedTags.length} onClick={submitTags}>
          Envoyer mes retours
        </button>
      </div>
      <Footer />
    </>
  );
}
